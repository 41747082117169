var PageVoting = new JS.Class({
  initialize: function(
    selector,
    pageId,
    votingData,
    media_url,
    size,
    scorePosition
  ) {
    this.pageId = pageId;

    this.votingWidget = new PageVotingWidget(
      $(selector),
      media_url,
      size,
      scorePosition
    );

    this.votingWidget.voteRequest.subscribe(this.method('voteForPage'));

    this.votingWidget.setVotingData(votingData);
  },

  voteForPage: function(votingWidget, voteValue) {
    var voteData = {
      documentType: 'page',
      documentId: this.pageId,
      voteValue: voteValue,
    };

    var that = this;

    $.ajax('/voting/vote/', {
      dataType: 'json',
      type: 'post',
      data: voteData,
      success: function(data) {
        if (data['success']) {
          that.votingWidget.setVotingData(data);
        }
      },
    });
  },
});
