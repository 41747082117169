var PageMetricsOverlay = function() {};

PageMetricsOverlay.prototype.hide = function() {
  if (this.overlay) {
    this.overlay.remove();
  }
  this.visible = false;
};

PageMetricsOverlay.prototype.load = function() {
  this.visible = true;

  var self = this;

  var query = window.location.search.slice(1).split('&');
  var suffix = '';
  $.each(query, function(i, pair) {
    var parts = pair.split('=');
    if (parts[0] === 'action') {
      suffix = '+' + parts[1];
    }
  });

  API.post('page-metric/averageData/', {
    path: window.location.pathname + suffix,
  }).done(function(response) {
    var data = response.data;

    var $overlay = $('<div>')
      .attr('id', 'page-metrics-overlay')
      .css({
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        'pointer-events': 'none',
        'z-index': '999999',
      })
      .appendTo($('body'));

    self.overlay = $overlay;

    var scrollDist = data.averageScrollDist;
    var clicks = data.clicksByTime;
    var overlayCanvas = $('<canvas>');

    overlayCanvas[0].width = $(document).innerWidth();
    overlayCanvas[0].height = $(document).innerHeight();

    var ctx = null;
    if (overlayCanvas[0].getContext) {
      ctx = overlayCanvas[0].getContext('2d');
    }

    var closeButton = $('<a>')
      .css({
        position: 'fixed',
        right: '10px',
        top: '50px',
        'font-size': '16px',
        'background-color': 'white',
        padding: '4px',
        'z-index': '9999999',
      })
      .attr('href', '#')
      .html('Close Metrics View &times;')
      .click(function() {
        $(this).remove();
        self.overlay.remove();
        self.visible = false;
        return false;
      })
      .appendTo($('body'));

    $overlay
      .append(
        $('<div>')
          .css({
            position: 'absolute',
            left: '0',
            top: scrollDist + 'px',
            'border-top': '2px solid red',
            'font-size': '10px',
            width: '100%',
            'background-color': 'white',
          })
          .text('Average Scroll Distance')
      )
      .append(
        $('<div>')
          .css({
            position: 'fixed',
            left: '10px',
            top: '50px',
            'font-size': '16px',
            'background-color': 'white',
            padding: '4px',
            border: '1px solid #eee',
          })
          .text('Page Views: ' + data.pageViews)
      )
      .append(
        $('<div>')
          .css({
            position: 'fixed',
            left: '10px',
            top: '90px',
            'font-size': '16px',
            'background-color': 'white',
            padding: '4px',
            border: '1px solid #eee',
          })
          .text('Unique Views: ' + data.usersViewed)
      )
      .append(
        $('<div>')
          .css({
            position: 'fixed',
            left: '10px',
            top: '130px',
            'font-size': '16px',
            'background-color': 'white',
            padding: '4px',
            border: '1px solid #eee',
          })
          .text(
            'Average Time Spent on Page: ' +
              Math.round(data.averageActiveTime / 1000.0) +
              's'
          )
      )
      .append(overlayCanvas);

    if (ctx !== null) {
      ctx.fillStyle = 'rgba(255,0,0,0.3)';

      $.each(clicks, function(time, click) {
        ctx.beginPath();
        ctx.arc(click.x, click.y, 8, 0, 2 * Math.PI, false);
        ctx.fill();
      });
    }
  });
};
