var Emoticons = function(baseURL) {
  var emoticons = {
    translations: {
      'O:)': 'angel.png',
      '>:(': 'angry.png',
      '^_^': 'aww.png',
      ':$': 'blushing.png',
      ':S': 'confused.png',
      'B)': 'cool.png',
      ":'(": 'crying.png',
      ';3': 'cute_winking.png',
      ':3': 'cute.png',
      '>:)': 'devil.png',
      ':(': 'frowning.png',
      ':o': 'gasping.png',
      ':D': 'grinning.png',
      '<3': 'heart.png',
      '>_<': 'irritated.png',
      '>.<': 'irritated_2.png',
      XD: 'laughing.png',
      ':x': 'lips_sealed.png',
      '>:D': 'malicious.png',
      ':}': 'naww.png',
      ':c': 'pouting.png',
      '^.^': 'shy.png',
      'X\\': 'sick.png',
      'X/': 'sick.png',
      ':)': 'smiling.png',
      ':|': 'speechless.png',
      o_O: 'surprised.png',
      O_o: 'surprised_2.png',
      'D:': 'terrified.png',
      ';;': 'thumbs_down.png',
      '!!': 'thumbs_up.png',
      ':P': 'tongue_out.png',
      ':\\': 'unsure.png',
      ':/': 'unsure_2.png',
      ';D': 'winking_grinning.png',
      ';P': 'winking_tongue_out.png',
      ';)': 'winking.png',
      '8{{': 'cthulhu.png',
      '$)': 'greedy.png',
      ':d': 'tongue_out_up_left.png',

      '::happy::': 'happy.png',
      '::smiling::': 'smiling.png',
      '::tired::': 'tired.png',

      '::mushtak::': 'mushtak.png',
      '::richard::': 'richard.png',
      '::emila::': 'emila.png',
      '::danny::': 'danny.png',
      '::brooke::': 'brooke.png',
    },

    insertIntoText: function() {
      if (arguments.length === 1) {
        iconPathPrefix = baseURL;
        text = arguments[0];
      } else {
        iconPathPrefix = arguments[0];
        text = arguments[1];
      }

      var iconPathPrefix;
      var text;
      var emot;
      var img;
      var tag;
      var l;
      var i;
      var textSections;
      var len;
      var lines;
      var line;
      var originalText;
      var emoticonKey;

      var entityMap = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;',
        '/': '&#x2F;',
      };

      var escapeHTML = function(string) {
        return String(string).replace(/[&<>"'\/]/g, function(s) {
          return entityMap[s];
        });
      };

      var unescapeHTML = function(string) {
        for (var original in entityMap) {
          string = String(string).replace(entityMap[original], original);
        }

        return string;
      };

      function replaceURLWithHTMLLinks(text) {
        text = text.replace(/(\s|^)(www\.)([a-zA-Z0-9])/, '$1http://www.$3');
        var exp = /(\b(https?|ftp|mailto):(\/\/|&#x2F;&#x2F;)[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
        return text.replace(exp, '<a href="$1" target="_blank">$1</a>');
      }

      text = escapeHTML(text);

      originalText = text;

      text = replaceURLWithHTMLLinks(text);

      lines = text.replace(/\r\n/g, '\n').split(/\n/);

      for (l = 0; l !== lines.length; ++l) {
        line = lines[l];

        textSections = line.split(/ /);
        len = textSections.length;

        for (i = 0; i !== len; ++i) {
          emoticonKey = unescapeHTML(textSections[i]);

          if (emoticonKey.indexOf && emoticonKey.indexOf('::') == 0) {
            emoticonKey = emoticonKey.toLowerCase();
          }

          // The below check prevents things like the watch function from being treated as emoticon keys
          if (emoticons.translations.hasOwnProperty(emoticonKey)) {
            emot = emoticons.translations[emoticonKey];
            img = iconPathPrefix + emot;
            tag =
              '<img src="' +
              img +
              '" class="emoticon" alt="' +
              textSections[i] +
              '">';
            textSections[i] = tag;
          }
        }

        lines[l] = textSections.join(' ');
      }

      text = lines.join('\n');

      return $.trim(text);
    },
  };

  return emoticons;
};
