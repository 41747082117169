var DjangoCSRF = new JS.Class({
  initialize: function() {},
});

$.ajaxPrefilter(function(settings, originalSettings, xhr) {
  function safeMethod(method) {
    return /^(GET|HEAD|OPTIONS|TRACE)$/.test(method);
  }

  if (!safeMethod(settings.type) && !settings.crossDomain) {
    var csrfToken = Utilities.getCookie('csrftoken');
    xhr.setRequestHeader('X-CSRFToken', csrfToken);
    settings.csrfToken = csrfToken;
  } else if (Utilities.sameOrigin(settings.url, olOrigin)) {
    settings.xhrFields = { withCredentials: true };
    if (!safeMethod(settings.type) && typeof referrerHMAC !== 'undefined') {
      xhr.setRequestHeader('Referrer-HMAC', referrerHMAC);
      settings.referrerHMAC = referrerHMAC;
    }
  }
});
