var RESOURCE = {
  _path: function() {
    var args = Array.prototype.slice.call(arguments);
    return '/json/' + args.join('/') + '/';
  },

  _send: function(type, url, data, options) {
    var key;
    var deferred = $.Deferred();

    data = data || {};

    for (key in data) {
      if (
        data.hasOwnProperty(key) &&
        typeof data[key] !== 'string' &&
        data[key] !== null &&
        data[key] !== undefined
      ) {
        if (typeof data[key] === 'object' || data[key].constructor === Array) {
          data[key] = JSON.stringify(data[key]);
        } else if (typeof data[key] === 'boolean') {
          data[key] = data[key] ? 'true' : 'false';
        } else {
          data[key] = '' + data[key];
        }
      }
    }

    var ajaxOptions = {
      url: url,
      type: type,
      data: data,
      dataType: 'json',
    };

    options = options || {};
    $.extend(ajaxOptions, options);

    var jqXHR = $.ajax(ajaxOptions)
      .done(function(response) {
        var result;
        if (!response.hasOwnProperty('error') && response.success !== false) {
          if (data['methodsJSON']) {
            result = [];

            $.each(response.result, function(i, r) {
              result.push(r.result);
            });
          } else {
            result = response.result;
          }

          if (response.id) {
            deferred.resolve(result, response.id, response);
          } else {
            deferred.resolve(result, response);
          }
        } else {
          var error =
            response && response.hasOwnProperty('error')
              ? result.error
              : 'Error: Request Failed';
          deferred.reject('Error ' + error + ': ' + response.reason, response);
        }
      })
      .fail(function(response) {
        var parsed_result;

        if (response.hasOwnProperty('responseJSON')) {
          response = response.responseJSON;
        }

        if (
          (response.hasOwnProperty('error') &&
            response.error.constructor === String) ||
          response.success === false
        ) {
          deferred.reject(
            'Error ' + response.error + ': ' + response.reason,
            response
          );
        } else {
          try {
            parsed_result = JSON.parse(response.responseText);
          } catch (err) {
            parsed_result = {
              error: response.status + ': ' + response.statusText,
            };
          }
          deferred.reject('Error: ' + parsed_result.error, response);
          if (parsed_result.errorCode === 4) {
            window.location.reload();
          }
        }
      });

    deferred.abort = jqXHR.abort;

    return deferred;
  },

  create: function(resource, data, methods, options) {
    var RESOURCE = this;
    var url;

    if (Object.prototype.toString.call(methods) === '[object Array]') {
      url = RESOURCE._path(resource, 'multi/create');
      return RESOURCE._send(
        'POST',
        url,
        {
          creationData: JSON.stringify(data),
          methodsJSON: JSON.stringify(methods),
        },
        options
      );
    } else {
      options = methods; // missing the methods arg
      url = RESOURCE._path(resource, 'create');
      return RESOURCE._send('POST', url, data, options);
    }
  },

  read: function(resource, resourceID, method, args, options) {
    var RESOURCE = this;
    var url;

    if (Object.prototype.toString.call(method) === '[object Array]') {
      url = RESOURCE._path(resource, 'multi/read', resourceID);
      return RESOURCE._send(
        'GET',
        url,
        { methodsJSON: JSON.stringify(method) },
        args
      );
    } else {
      url = RESOURCE._path(resource, 'read', method, resourceID);
      return RESOURCE._send('GET', url, args, options);
    }
  },

  update: function(resource, resourceID, method, args, options) {
    var RESOURCE = this;
    var url;

    if (Object.prototype.toString.call(method) === '[object Array]') {
      url = RESOURCE._path(resource, 'multi/update', resourceID);
      return RESOURCE._send(
        'POST',
        url,
        { methodsJSON: JSON.stringify(method) },
        args
      );
    } else {
      url = RESOURCE._path(resource, 'update', method, resourceID);
      return RESOURCE._send('POST', url, args, options);
    }
  },

  delete: function(resource, resourceID, options) {
    var RESOURCE = this;
    var url = RESOURCE._path(resource, 'delete', resourceID);
    return RESOURCE._send('POST', url, {}, options);
  },

  lookup: function(resource, method, args, options) {
    var RESOURCE = this;
    var url = RESOURCE._path(resource, 'lookup', method);
    return RESOURCE._send('GET', url, args, options);
  },

  execute: function(resource, method, args, options) {
    var RESOURCE = this;
    var url = RESOURCE._path(resource, 'execute', method);
    return RESOURCE._send('POST', url, args, options);
  },
};
