(function($) {
    var DEFAULT_OPTIONS = {
        exclude: '',
        timeout: 500
    };

    function initialise(form, options) {
        var $elements;
        if (options.elements) {
            $elements = options.elements;
        } else {
            $elements = $(form.elements);
        }

        var url = form.action;
        var type = form.method;

        var savedClass = 'loader-succeeded';
        var errorClass = 'loader-failed';
        var savingClass = 'loader-icon';

        if (options.exclude) {
            $elements = $elements.not(options.exclude);
        }

        $elements.not('button, input[type="button"], input[type="submit"], input[type="file"]').each(function(i, element) {
            var $elt = $(element);
            var $statusElt;
            var statusSelector = $elt.attr('data-save-status');
            if (statusSelector === 'none') {
                return; // disabled
            } else if (statusSelector) {
                $statusElt = $(statusSelector);
            } else if ($elt.attr('type') === 'hidden' || $elt.hasClass('select2-focusser')) {
                // hidden elements and select2s aren't auto-created unless a specific selector is given
                return;
            }

            if (!statusSelector || $statusElt.length == 0) {
                $statusElt = $('<span>');
                if ($elt.parent().is('label') || $elt.parent().hasClass('controls')) {
                    $elt.parent().append($statusElt);
                } else {
                    $statusElt.insertAfter($elt);
                }
            }
            $statusElt.addClass('save-status').hide();

            $elt.on('save-status', function() {
                clearTimeout($statusElt.data('timeout'));
                $statusElt
                    .removeClass(errorClass)
                    .removeClass(savedClass)
                    .addClass(savingClass)
                    .show();
            });

            $elt.on('save-error-status', function() {
                $statusElt
                    .removeClass(savedClass)
                    .removeClass(savingClass)
                    .addClass(errorClass)
                    .tooltip({'title': 'An error has occurred, please try again.'});
            });

            $elt.on('saved-status', function() {
                $statusElt
                    .removeClass(errorClass)
                    .removeClass(savingClass)
                    .addClass(savedClass);

                $statusElt.data('timeout', 
                    setTimeout(function() {
                        $statusElt.fadeOut(function() {
                            $(this)
                                .removeClass(savedClass);
                        });
                    }, 3000)
                );
            });
        });

        $elements.not('input[type="text"], textarea, input[type="file"]').change(function(e) {
            autosave($(this));
        });

        $elements.filter('input[type="text"], textarea').each(function(i, element) {
            var $element = $(element);
            $element.data('original', $element.val());
        }).on('keyup change', function(e) {
            var $this = $(this);
            var timer = $this.data('timer');
            if (timer) {
                clearTimeout(timer);
            }
            if (e.type === 'keyup') {
                timer = setTimeout(function() {
                    autosaveIfDifferent($this);
                    $this.data('timer', null);
                }, options.timeout);
            } else {
                autosaveIfDifferent($this);
                timer = null;
            }
            $this.data('timer', timer);
        });
    }

    function autosave($element) {
        var form = $element.prop('form');

        if (!form) {
            return; // select 2 sometimes does this
        }

        if ($element.is('input[type="checkbox"]')) {
            var data = {};
            data[$element.attr('name')] = $element.is(':checked') ? 1 : 0;
        } else {
            var data = $element.serialize();
        }

        var settings = {
             data: data,
             beforeSend: function(jqxhr, settings) {
                $element.trigger('save-status');
             },
             error: function(jqxhr, status, error) {
                $element.trigger('save-error-status');
             },
             success: function(data, jqxhr, settings) {
                $element.trigger('saved-status');
             }
        };

        // conditionally add these as IE will throw a syntax error
        // if they are empty strings (but undefined is ok)
        if (form.method) {
            settings.type = form.method;
        }

        if (form.action) {
            settings.url = form.action;
        }

        var jqXHR = $.ajax(settings);

        $element.trigger('autosave', [jqXHR]);
    }

    function autosaveIfDifferent($element) {
        var newVal = $element.val();
        if (newVal !== $element.data('original')) {
            autosave($element);
            $element.data('original', newVal);
        }
    }

    $.fn.autosave = function(options) {
        options = $.extend({}, DEFAULT_OPTIONS, options);
        this.each(function(i, element) {
            initialise(element, options);
        });
    };
})(jQuery);
