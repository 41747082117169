API = new JS.Class({
  initialize: function(url, method) {
    if (url.indexOf('?') === -1) {
      this.url = '/api/' + (url.endsWith('/') ? url : url + '/');
    } else {
      var parts = url.split('?');
      parts[0] = parts[0].endsWith('/') ? parts[0] : parts[0] + '/';
      this.url = '/api/' + parts.join('?');
    }
  },

  _send: function(type, data, options) {
    var deferred = $.Deferred();
    var errorMessage;
    var ajaxOptions = {
      url: this.url,
      type: type,
      data: data,
      dataType: 'json',
    };

    $.extend(ajaxOptions, options);

    var jqXHR = $.ajax(ajaxOptions)
      .done(function(result) {
        if (!result.hasOwnProperty('error') && result.success != false) {
          deferred.resolve(result);
        } else {
          var error =
            result && result.hasOwnProperty('error') ? result.error : 'no data';
          deferred.reject('Error: ' + error, result);
        }
      })
      .fail(function(result) {
        var parsed_result;
        try {
          parsed_result = JSON.parse(result.responseText);
          errorMessage = 'Error: ' + parsed_result.error;

          deferred.reject(errorMessage, parsed_result);
          if (parsed_result.errorCode === 4) {
            window.location.reload();
          }
        } catch (err) {
          errorMessage = 'Error ' + result.status + ': ' + result.statusText;
        }
      });

    deferred.abort = jqXHR.abort;

    return deferred;
  },

  get: function(data, options) {
    return this._send('GET', data, options);
  },

  post: function(data, options) {
    return this._send('POST', data, options);
  },

  put: function(data, options) {
    return this._send('PUT', data, options);
  },

  del: function(data, options) {
    return this._send('DELETE', data, options);
  },
});

API.extend({
  get: function(url, data, options) {
    return new this(url).get(data, options);
  },

  post: function(url, data, options) {
    return new this(url).post(data, options);
  },

  put: function(url, data, options) {
    return new this(url).put(data, options);
  },

  del: function(url, data, options) {
    return new this(url).del(data, options);
  },
});
