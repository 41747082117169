var Utilities = new JS.Class({
  extend: {
    // pads a string with 0
    zfill: function(number, length) {
      var str = '' + number;
      while (str.length < length) {
        str = '0' + str;
      }

      return str;
    },

    // Converts an iso-format timestamp to 01 January 2000, 01:00:00
    formatTimestamp: function(isoTimestamp, format) {
      if (format == undefined) {
        format = '%a, %d %b %Y %I:%M%p';
      }

      var t =
        typeof isoTimestamp === 'string'
          ? this.parseISODate(isoTimestamp)
          : isoTimestamp;

      // JAVASCRIPT Y U NO HAVE FORMAT STRINGS?!
      var day = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

      // I can't believe I have to do this
      var month = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];

      var fullMonth = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];

      var weekdays = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ];

      var meridiem = 'am';
      var hours = t.getHours();
      var hours24 = t.getHours();

      if (hours > 11) {
        hours -= 12;
        meridiem = 'pm';
      }

      if (hours == 0) {
        hours = 12;
      }

      var formattedTimestamp = format
        .replace(/%a/g, day[t.getDay()])
        .replace(/%A/g, weekdays[t.getDay()])
        .replace(/%d/g, this.zfill(t.getDate(), 2))
        .replace(/%D/g, t.getDate())
        .replace(/%b/g, month[t.getMonth()])
        .replace(/%B/g, fullMonth[t.getMonth()])
        .replace(/%Y/g, t.getFullYear())
        .replace(/%I/g, this.zfill(hours, 2))
        .replace(/%H/g, this.zfill(hours24, 2))
        .replace(/%M/g, this.zfill(t.getMinutes(), 2))
        .replace(/%S/g, this.zfill(t.getSeconds(), 2))
        .replace(/%p/g, meridiem)
        .replace(/%P/g, meridiem.toUpperCase());
      return formattedTimestamp;
    },

    number_format: function(number, decimals, dec_point, thousands_sep) {
      var n = number,
        c = isNaN((decimals = Math.abs(decimals))) ? 2 : decimals;
      var d = dec_point == undefined ? ',' : dec_point;
      var t = thousands_sep == undefined ? '.' : thousands_sep,
        s = n < 0 ? '-' : '';
      var i = parseInt((n = Math.abs(+n || 0).toFixed(c))) + '',
        j = (j = i.length) > 3 ? j % 3 : 0;

      return (
        s +
        (j ? i.substr(0, j) + t : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) +
        (c
          ? d +
            Math.abs(n - i)
              .toFixed(c)
              .slice(2)
          : '')
      );
    },

    money_format: function(number, currency, verbose) {
      if (currency == undefined) {
        currency = 'AUD';
      }

      var preAmountText = '';
      var postAmountText = '';
      var decimalPlaces = 2;
      var decimalSeparator = '.';
      switch (currency) {
        case 'AUD':
        case 'USD':
          preAmountText = '$';
          if (verbose) {
            postAmountText = ' ' + currency;
          }
          break;
        case 'GBP':
          postAmountText = ' GBP';
          break;
      }
      return (
        preAmountText +
        this.number_format(number, decimalPlaces, decimalSeparator) +
        postAmountText
      );
    },
    // From http://snipplr.com/view/5945/javascript-numberformat--ported-from-php/
    // and http://snipplr.com/view/5949/
    size_format: function(filesize) {
      if (filesize >= 1073741824) {
        filesize =
          this.number_format(filesize / 1073741824, 2, '.', '') + ' GB';
      } else {
        if (filesize >= 1048576) {
          filesize = this.number_format(filesize / 1048576, 2, '.', '') + ' MB';
        } else {
          if (filesize >= 1024) {
            filesize = this.number_format(filesize / 1024, 0) + ' KB';
          } else {
            filesize = this.number_format(filesize, 0) + ' bytes';
          }
        }
      }
      return filesize;
    },

    escape: function(unsafe) {
      return $('<div/>')
        .text(unsafe)
        .html();
    },

    parseURL: function(url) {
      var link = document.createElement('a');
      link.href = url;
      return link;
    },

    parseYouTube: function(url) {
      // returns YouTube ID from embed code or url
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
      var match = url.match(regExp);

      if (match && match[7].length == 11) {
        return match[7];
      } else {
        return null;
      }
    },

    // http://stackoverflow.com/questions/1184624/convert-form-data-to-js-object-with-jquery
    serializeObject: function($formElement) {
      var o = {};
      var a = $formElement.serializeArray();
      $.each(a, function() {
        if (o[this.name] !== undefined) {
          if (!o[this.name].push) {
            o[this.name] = [o[this.name]];
          }
          o[this.name].push(this.value || '');
        } else {
          o[this.name] = this.value || '';
        }
      });
      return o;
    },

    addParams: function(url, params) {
      if (typeof url === 'string') {
        url = this.parseURL(url);
      }
      queryString = $.param(params);
      if (queryString) {
        url.search += (url.search ? '&' : '?') + queryString;
      }
      return url.href;
    },

    getCookie: function(name) {
      var cookieValue = null;
      if (document.cookie && document.cookie != '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
          var cookie = jQuery.trim(cookies[i]);
          // Does this cookie string begin with the name we want?
          if (cookie.substring(0, name.length + 1) == name + '=') {
            cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
            break;
          }
        }
      }
      return cookieValue;
    },

    deleteCookie: function(name) {
      document.cookie = name + '=; expires=Fri, 01 Jan 2000 01:00:00 UTC;';
    },

    sameOrigin: function(url1, url2) {
      // same origin has same host (hostname + port) & protocol
      var a = this.parseURL(url1);
      var b = this.parseURL(url2);
      return a.protocol === b.protocol && a.host === b.host;
    },

    getParameter: function(uri, name) {
      // http://stackoverflow.com/questions/901115/how-can-i-get-query-string-values
      name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
      var regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
        results = regex.exec(location.search);
      return results == null
        ? ''
        : decodeURIComponent(results[1].replace(/\+/g, ' '));
    },

    updateQueryStringParameter: function(uri, key, value) {
      // from http://stackoverflow.com/questions/5999118/add-or-update-query-string-parameter
      var re = new RegExp('([?|&])' + key + '=.*?(&|$)', 'i');
      var url = this.parseURL(uri);
      var queryString = url.search;
      var separator = queryString.indexOf('?') !== -1 ? '&' : '?';
      if (queryString.match(re)) {
        if (typeof value === 'undefined') {
          queryString = queryString.replace(re, '$1' + '$2');
        } else {
          queryString = queryString.replace(
            re,
            '$1' + key + '=' + value + '$2'
          );
        }
      } else {
        if (typeof value !== 'undefined') {
          queryString += separator + key + '=' + value;
        }
      }
      url.search = queryString;
      return url.href;
    },

    // from http://stackoverflow.com/questions/1634748/how-can-i-delete-a-query-string-parameter-in-javascript
    removeParameter: function(url, parameter) {
      url = this.parseURL(url);

      if (url.search.length > 2) {
        var queryString = url.search.slice(1);

        var prefix = encodeURIComponent(parameter) + '=';
        var pars = queryString.split(/[&;]/g);
        for (
          var i = pars.length;
          i-- > 0; //reverse iteration as may be destructive

        )
          if (pars[i].lastIndexOf(prefix, 0) !== -1)
            //idiom for string.startsWith
            pars.splice(i, 1);

        url.search = '?' + pars.join('&');
      }
      return url.href;
    },

    toTitleCase: function(text) {
      return text.toLowerCase().replace(/\b(\w)/g, function(match, letter) {
        return letter.toUpperCase();
      });
    },
  },
});

var shims = {};

if ('keys' in Object) {
  shims['getKeys'] = Object.keys;
} else {
  shims['getKeys'] = function(obj) {
    return $.map(obj, function(v, k) {
      return k;
    });
  };
}

if ('toISOString' in new Date()) {
  shims['parseISODate'] = function(isoString) {
    return new Date(isoString);
  };
} else {
  shims['parseISODate'] = function(isoString) {
    var d;
    var matches =
      isoString &&
      isoString.match(
        /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(\.(\d{3}))?Z$/
      );
    if (matches) {
      d = new Date();
      d.setUTCFullYear(matches[1], matches[2] - 1, matches[3]);
      d.setUTCHours(matches[4], matches[5], matches[6], matches[8] || 0);
    } else {
      d = new Date(isoString);
    }
    return d;
  };
}

/* https://developer.mozilla.org/en-US/docs/JavaScript/Reference/Global_Objects/Date/toISOString */
if (!Date.prototype.toISOString) {
  (function() {
    function pad(number) {
      var r = String(number);
      if (r.length === 1) {
        r = '0' + r;
      }
      return r;
    }

    Date.prototype.toISOString = function() {
      return (
        this.getUTCFullYear() +
        '-' +
        pad(this.getUTCMonth() + 1) +
        '-' +
        pad(this.getUTCDate()) +
        'T' +
        pad(this.getUTCHours()) +
        ':' +
        pad(this.getUTCMinutes()) +
        ':' +
        pad(this.getUTCSeconds()) +
        '.' +
        String((this.getUTCMilliseconds() / 1000).toFixed(3)).slice(2, 5) +
        'Z'
      );
    };
  })();
}

Utilities.extend(shims);
