var OpenLearning_mobile = (function($) {

	var OpenLearning_mobile = {
		courseLinks: null,
		moduleSetsLinks: null,
		relatedPages: null,
		courseLinksParent: null,
		isBigHeader: false,
		isMobile: false,
		sideNav: null,
		sideNavParent: null,

		doResponsive: function() {
			var width = window.innerWidth ||
						document.documentElement.clientWidth ||
						document.body.clientWidth;

			if(width <= 940){
				$('.mobile-tohide-status').hide();
			}else{
				$('.mobile-tohide-status').show();
			}

			if (width <= 767) {
				$('body').removeClass('bigheader');

				if (!OpenLearning_mobile.isMobile) {
					//Move contextual menu from the page into the mobile menu
					OpenLearning_mobile.courseLinks.appendTo($('#mobile-menu'));

					// TODO for now we just hide
					OpenLearning_mobile.moduleSetsLinks.hide();

					//Course home
					$('.pageOptionText').hide();

				}

				OpenLearning_mobile.isMobile = true;
			} else {
				$('body').addClass('bigheader');

				if (OpenLearning_mobile.isMobile) {
					//Move contextual menu from the mobile menu back into the page
					OpenLearning_mobile.courseLinks.appendTo(OpenLearning_mobile.courseLinksParent);

					// TODO for now we just show it again
					OpenLearning_mobile.moduleSetsLinks.show();

					//Course home
					$('.pageOptionText').show();
				}

				OpenLearning_mobile.isMobile = false;
			}
		},

		init: function() {


			$(function() {
				OpenLearning_mobile.site_container = $('#site-container');
				OpenLearning_mobile.mobile_left_panel = $('#mobile-left-panel');
				OpenLearning_mobile.mobile_notifications_menu_btn = $('#mobile-notifications-menu-btn');
				OpenLearning_mobile.mobile_chat_btn = $('#mobile-chat-btn');

				OpenLearning_mobile.courseLinks = $('#course-links');
				OpenLearning_mobile.moduleSetsLinks = $('#module-sets');
				OpenLearning_mobile.relatedPages = $('#related-pages');
				OpenLearning_mobile.allCourses = $('#all-courses');
				OpenLearning_mobile.isBigHeader = $('body').hasClass('bigheader');

				OpenLearning_mobile.courseLinksParent = OpenLearning_mobile.courseLinks.parent();
				OpenLearning_mobile.relatedPagesParent = OpenLearning_mobile.relatedPages.parent();
				OpenLearning_mobile.allCoursesParent = OpenLearning_mobile.allCourses.parent();

				OpenLearning_mobile.sideNav = $('#moveSideNav');
				OpenLearning_mobile.sideNavParent = OpenLearning_mobile.sideNav.parent();

				OpenLearning_mobile.doResponsive();
				$(document).ready(function() {
					OpenLearning_mobile.doResponsive();
				});
				$(window).on('load', function() {
					OpenLearning_mobile.doResponsive();
				});
				$(window).on('resize', OpenLearning_mobile.doResponsive);

			});
		}
	};
	return OpenLearning_mobile;
})(jQuery);
