var renderTemplate = function(name, data, callback) {
  var scriptLocation;
  if (typeof data === 'function') {
    callback = data;
    data = {};
  }
  if (name in dust.cache) {
    // already cached
    return dust.render(name, data, callback);
  } else {
    scriptLocation = baseMedia + templating.getCompiledScriptLocation(name);

    if (scriptLocation in templating.loaders) {
      // currently downloading
      templating.loaders[scriptLocation].done(function() {
        return dust.render(name, data, callback);
      });
    } else {
      // not yet downloading
      templating.loaders[scriptLocation] = $.ajax({
        dataType: 'script',
        cache: templating.isCached,
        url: scriptLocation,
      })
        .done(function() {
          return dust.render(name, data, callback);
        })
        .fail(function() {
          callback('Unable to load script: ' + scriptLocation, null);
          return null;
        });
    }
  }
};
