var FacebookClass = new JS.Class({
  initialize: function() {
    // Lazy initialisation
    this.deferred = $.Deferred();
    this.ready = false;
  },

  load: function() {
    var that = this;
    window.fbAsyncInit = function() {
      FB.init({
        appId: '203356799790933',
        version: 'v2.8',
        channelUrl: '//www.openlearning.com/media/facebook/channel.html',
        status: true, // check login status
        cookie: true, // enable cookies to allow the server to access the session
        xfbml: true, // parse XFBML
      });

      that.ready = true;
      that.deferred.resolveWith(that);
    };

    // Load the SDK Asynchronously
    (function(d) {
      var js,
        id = 'facebook-jssdk',
        ref = d.getElementsByTagName('script')[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement('script');
      js.id = id;
      js.async = true;
      js.src = '//connect.facebook.net/en_US/sdk.js';
      ref.parentNode.insertBefore(js, ref);
    })(document);
  },

  loginToOpenLearning: function(facebookAuthResponse) {
    return $.ajax({
      url: olOrigin + '/auth/facebook',

      type: 'POST',

      data: {
        userID: facebookAuthResponse.userID,
        accessToken: facebookAuthResponse.accessToken,
        signedRequest: facebookAuthResponse.signedRequest,

        // for autoenrolment into course and autoverification of email
        verificationCode: $('input[name=verificationCode]').val(),
      },
    });
  },

  connect: function() {
    if (!this.ready) this.load();
    var result = $.Deferred();
    this.deferred.done(function() {
      var that = this;
      FB.getLoginStatus(function(response) {
        if (response.status === 'connected') {
          that.loginToOpenLearning(response.authResponse).done(function(data) {
            result.resolve(data);
          });
        } else {
          result.reject({ authenticated: false });
        }
      });
    });
    return result;
  },

  login: function() {
    if (!this.ready) this.load();
    var result = $.Deferred();
    this.deferred.done(function() {
      var that = this;
      FB.login(
        function(response) {
          if (response.authResponse) {
            that
              .loginToOpenLearning(response.authResponse)
              .done(function(data) {
                result.resolve(data);
              });
          } else {
            result.reject({ authenticated: false });
          }
        },
        { scope: 'public_profile,email' }
      );
    });
    return result;
  },

  logout: function() {
    if (!this.ready) this.load();
    var result = $.Deferred();
    this.deferred.done(function() {
      FB.getLoginStatus(function(response) {
        if (response.status === 'connected') {
          FB.logout(function(response) {
            result.resolve(response);
          });
        } else {
          result.reject();
        }
      }, true);
    });
    return result;
  },

  revoke: function() {
    if (!this.ready) this.load();
    var result = $.Deferred();
    this.deferred.done(function() {
      FB.api('/me/permissions', 'delete', function(response) {
        result.resolve(response);
      });
    });
    return result;
  },

  openFriendsDialog: function(courseName) {
    if (!this.ready) this.load();
    var result = $.Deferred();
    this.deferred.done(function() {
      var that = this;

      FB.ui(
        {
          appId: '203356799790933',
          method: 'apprequests',
          message: 'Invite friends to join ' + courseName + ' on OpenLearning',
          title: courseName + ' on OpenLearning',
        },
        function(response) {
          $(window).trigger('facebookFriendsAdded', { friends: response });
        }
      );
    });
    return result;
  },
});

Facebook = new FacebookClass();
