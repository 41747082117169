(function( jQuery ) {

if ( window.XDomainRequest && !$.support.cors ) {
	jQuery.ajaxTransport(function( s ) {
		if ( s.crossDomain && s.async ) {
			if ( s.timeout ) {
				s.xdrTimeout = s.timeout;
				delete s.timeout;
			}
			var xdr;
			return {
				send: function( _, complete ) {
					function callback( status, statusText, responses, responseHeaders ) {
						xdr.onload = xdr.onerror = xdr.ontimeout = xdr.onprogress = jQuery.noop;
						xdr = undefined;
						complete( status, statusText, responses, responseHeaders );
					}
					xdr = new XDomainRequest();
					// referrer is the current URL without the hash
					var referrer = location.hash ? location.href.slice(0, -location.hash.length) : location.href;
					var params = {'XDR-Referer': referrer};
					if (s.type !== 'GET' && s.type !== 'POST') {
						params['XDR-Request-Method'] = s.type;
					}
					if (s.contentType !== 'text/plain') {
						params['XDR-Content-Type'] = s.contentType;
					}
					if (s.xhrFields && s.xhrFields.withCredentials) {
						params['XDR-Cookie'] = document.cookie;
					}
					if (s.referrerHMAC) {
						params['XDR-Referrer-HMAC'] = s.referrerHMAC;
					}
					xdr.open( s.type, Utilities.addParams(s.url, params) );
					xdr.onload = function() {
						callback( 200, "OK", { text: xdr.responseText }, "Content-Type: " + xdr.contentType );
					};
					xdr.onerror = function() {
						callback( 404, "Not Found" );
					};
					xdr.onprogress = function() {};
					if ( s.xdrTimeout ) {
						xdr.ontimeout = function() {
							callback( 0, "timeout" );
						};
						xdr.timeout = s.xdrTimeout;
					}
					xdr.send( ( s.hasContent && s.data ) || null );
				},
				abort: function() {
					if ( xdr ) {
						xdr.onerror = jQuery.noop();
						xdr.abort();
					}
				}
			};
		}
	});
}
})( jQuery );

// Hack for JS.Require
var transport = transport || {};
transport.xdr = true;
