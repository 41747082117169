(function($) {
    $.makeClassPlugin = function(pluginName, classConstructor, defaultOptions, jQueryInterface) {
        if (jQueryInterface) {
            $[pluginName] = jQueryInterface;
        }

        if (!defaultOptions) {
            defaultOptions = {};
        }
        
        if (!classConstructor || !pluginName) {
            return;
        }
        
        $.fn[pluginName] = function() {
            var method, args, options, $elt, returnVal, instance;
            var slice = Array.prototype.slice;
            var unshift = Array.prototype.unshift;
            var isMethodCall = (typeof arguments[0] === 'string');
            var isConstructor = (typeof arguments[0] === 'object' || typeof arguments[0] === 'undefined');

            if (isMethodCall) {
                method = arguments[0];
                args = slice.call(arguments, 1);

                returnVal = null;
                this.each(function(i, elt) {
                    $elt = $(elt);
                    instance = $elt.data(pluginName);
                    if ($[pluginName]) {
                        unshift.call(args, $elt);
                        returnVal = $[pluginName][method].apply(instance, args);
                    } else {
                        returnVal = instance[method].apply(instance, args);
                    }
                });

                if (returnVal || returnVal === false) {
                    // non-chaining
                    return returnVal;
                }
            } else if (isConstructor) {
                if (typeof arguments[0] === 'object') {
                    options = $.extend({}, defaultOptions, arguments[0]);
                } else {
                    options = $.extend({}, defaultOptions);
                }

                this.each(function(i, elt) {
                    $elt = $(elt);
                    $elt.data(pluginName, new classConstructor($elt, options));
                });
            }

            // chaining
            return this;
        };
    };
})(jQuery);