var PageVotingWidget = new JS.Class({
  // This widget is based off the PageVotingWidget but without the ability to downvote.
  // It uses a star instead of an up arrow to represent an upvote

  initialize: function(
    votingContainerSelector,
    media_url,
    size,
    scorePosition
  ) {
    this.votingContainer = $(votingContainerSelector);
    this.votingData = null;
    this.votesChanged = new EventSubscribers('votesChanged');
    this.voteRequest = new EventSubscribers('voteRequest');
    this.size = size ? size : 'medium';
    this.scorePosition = scorePosition ? scorePosition : 'bottom';
    this.media_url = media_url;
    this.addTooltip = {
      title: 'Add To Favourites',
      placement: 'top',
      container: 'body',
    };
    this.removeTooltip = {
      title: 'Remove From Favourites',
      placement: 'top',
      container: 'body',
    };

    if (this.size === 'large') {
      this.iconSize = 'icon-3x';
    } else if (this.size === 'medium') {
      this.iconSize = 'icon-2x';
    } else {
      this.iconSize = '';
    }
  },

  setDisplayScore: function(displayScore) {
    if (displayScore <= 0) {
      this.animatedScore.html('');
    } else {
      this.animatedScore.text(displayScore);
    }
  },

  createScoreBox: function(displayScore) {
    this.animatedScore = $('<span style="position: relative;">');
    this.setDisplayScore(displayScore);
    this.scoreBox = $('<div class="score-box">')
      .addClass(this.scorePosition)
      .addClass(this.size)
      .append(this.animatedScore)
      .appendTo(this.votingContainer);
  },

  createFavouriteBox: function() {
    var self = this;
    this.favouriteBox = $(
      '<div class="favourite-box" style="position: relative;">'
    )
      .addClass(this.size)
      .appendTo(this.votingContainer);

    this.star = $(
      '<a href="#" class="favourite-icon">\
				<i class="icon-star-o ' +
        this.iconSize +
        '"></i>\
				<i class="icon-star ' +
        this.iconSize +
        '"></i>\
				<span class="screen-reader-text">Add this page to favourites</span>\
			</a>'
    )
      .appendTo(this.favouriteBox)
      .click(function() {
        if (
          self.votingData['iDislike'] === true ||
          self.votingData['iLike'] === true
        ) {
          self.voteRequest.publish(self, 0);
        } else {
          self.voteRequest.publish(self, 1);
        }

        if (!self.votingData['iLike']) {
          self.animatedScore.animate({
            top: '-30px',
          });
          self.direction = 'up';
        } else if (!self.votingData['iDislike']) {
          self.animatedScore.animate({
            top: '30px',
          });
          self.direction = 'down';
        }

        return false;
      });
  },

  animateFavouriteBox: function(displayScore) {
    // position the score relative to the star
    if (this.scorePosition === 'left') {
      this.favouriteBox.toggleClass('right', displayScore > 0);
    } else if (this.scorePosition === 'right') {
      this.favouriteBox.toggleClass('left', displayScore > 0);
    } else if (this.scorePosition === 'top') {
      this.favouriteBox.toggleClass('bottom', displayScore > 0);
    } else {
      this.favouriteBox.toggleClass('top', displayScore > 0);
    }
  },

  animateStar: function() {
    // activate or unactivate the favourite icon and change the tooltip
    if (this.votingData['iLike'] === true) {
      this.star.removeClass('unactive').addClass('active');
    } else if (this.star.hasClass('active')) {
      // on initial load we don't need to animate if it's not active
      this.star.removeClass('active').addClass('unactive');
    }
  },

  updateTooltip: function() {
    // change the tooltip
    if (this.votingData['iLike'] === true) {
      this.favouriteBox.tooltip('destroy').tooltip(this.removeTooltip);
    } else {
      this.favouriteBox.tooltip('destroy').tooltip(this.addTooltip);
    }
  },

  setVotingData: function(votingData) {
    this.votingData = votingData;
    this.updateVoting();
    this.votesChanged.publish(this, votingData);
  },

  updateVoting: function() {
    var likeCount = this.votingData['votesUpCount'];
    var dislikeCount = this.votingData['votesDownCount'];
    var totalScore = likeCount - dislikeCount;
    var displayScore = Math.max(0, totalScore);

    if (this.votingContainer.data('loaded')) {
      this.setDisplayScore(displayScore);
      this.animateFavouriteBox(displayScore);
      this.animatedScore.stop(true);

      if (this.direction) {
        if (this.direction === 'up') {
          this.animatedScore.css({
            top: '30px',
          });
        } else if (this.direction === 'down') {
          this.animatedScore.css({
            top: '-30px',
          });
        }
      }

      this.animatedScore.animate({
        top: '0px',
      });
    } else {
      this.votingContainer.html('');
      this.createFavouriteBox();
      this.createScoreBox(displayScore);
      this.animateFavouriteBox(displayScore);
      this.votingContainer.data('loaded', true);
    }

    this.animateStar();
    this.updateTooltip();
  },
});
