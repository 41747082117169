var EventSubscribers = new JS.Class({
  initialize: function(eventName) {
    this.eventName = eventName; // for debugging
    this.subscribers = [];
  },

  subscribe: function(subscriber) {
    this.subscribers.push(subscriber);
  },

  publish: function() {
    var publishArguments = arguments;
    $.each(this.subscribers, function(i, subscriber) {
      subscriber.apply(null, publishArguments);
    });
  },
});
