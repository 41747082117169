var OpenLearning = new JS.Class({
  initialize: function() {},
});

OpenLearning.doAjaxForm = function(form) {
  var formData = $(form).serialize();
  var formAction = $(form).attr('action');
  var formMethod = $(form).attr('method');
  var inputs = $(form).find('input,textarea');
  var loaderArea = $(form).find('.loader');

  var loader = $(
    '<span class="loader-placeholder loader-icon" style="margin: 0px 4px 0px 0px; float: right;"></span>'
  );

  inputs.prop('disabled', true);
  loaderArea.html(loader);

  $.ajax(formAction, {
    type: formMethod,
    data: formData,
    success: function() {
      loaderArea.html('&#x2714;');

      $(inputs).on('change keyup keydown focus', function() {
        loaderArea.html('');
      });
    },
    error: function() {
      loaderArea.html('Error.');
    },
    complete: function() {
      inputs.removeProp('disabled');
      loader.remove();
    },
  });

  return false;
};

OpenLearning.userData = window.OpenLearningUserData;
