window.templating = window.templating || {};
window.templating.dustLocations = {
  "administratorRow": "lib/dust/compiled_templates/administratorAccess.dust",
  "prereqRow": "lib/dust/compiled_templates/administratorAccess.dust",
  "archiveList": "lib/dust/compiled_templates/album.dust",
  "singleBlogPost": "lib/dust/compiled_templates/blog.dust",
  "chatHistoryBase": "lib/dust/compiled_templates/chatHistory.dust",
  "chatHistoryConversation": "lib/dust/compiled_templates/chatHistory.dust",
  "chatHistoryConversationItem": "lib/dust/compiled_templates/chatHistory.dust",
  "chatHistoryMessageItem": "lib/dust/compiled_templates/chatHistory.dust",
  "message": "lib/dust/compiled_templates/chatroom.dust",
  "pageChat": "lib/dust/compiled_templates/chatroom.dust",
  "pageChatHistory": "lib/dust/compiled_templates/chatroom.dust",
  "classStats": "lib/dust/compiled_templates/classStats.dust",
  "pageoverview": "lib/dust/compiled_templates/classStats.dust",
  "trendingpages": "lib/dust/compiled_templates/classStats.dust",
  "courseContentWidget": "lib/dust/compiled_templates/courseContentWidget.dust",
  "desktopChatBox": "lib/dust/compiled_templates/desktopChat.dust",
  "desktopChatBoxes": "lib/dust/compiled_templates/desktopChat.dust",
  "desktopChatCohortFilterItem": "lib/dust/compiled_templates/desktopChat.dust",
  "desktopChatContainerContents": "lib/dust/compiled_templates/desktopChat.dust",
  "desktopChatListOnline": "lib/dust/compiled_templates/desktopChat.dust",
  "desktopChatMessageItem": "lib/dust/compiled_templates/desktopChat.dust",
  "desktopChatPerson": "lib/dust/compiled_templates/desktopChat.dust",
  "desktopChatPopover": "lib/dust/compiled_templates/desktopChat.dust",
  "userFavouritePage": "lib/dust/compiled_templates/favourites.dust",
  "groupBox": "lib/dust/compiled_templates/groups.dust",
  "institutionUserGroupCohortSelector": "lib/dust/compiled_templates/institution.dust",
  "institutionUserGroupEnrolmentResults": "lib/dust/compiled_templates/institution.dust",
  "institutionUserGroups": "lib/dust/compiled_templates/institution.dust",
  "institutionAddCourseDropdown": "lib/dust/compiled_templates/institutionAdmin.dust",
  "institutionAddGroupDropdown": "lib/dust/compiled_templates/institutionAdmin.dust",
  "institutionAdminAddToCourseStatus": "lib/dust/compiled_templates/institutionAdmin.dust",
  "institutionAdminAddToGroupStatus": "lib/dust/compiled_templates/institutionAdmin.dust",
  "institutionAdminCohortContextMapping": "lib/dust/compiled_templates/institutionAdmin.dust",
  "institutionCourseCategories": "lib/dust/compiled_templates/institutionAdmin.dust",
  "institutionCourseCategory": "lib/dust/compiled_templates/institutionAdmin.dust",
  "institutionCourseCategoryOptions": "lib/dust/compiled_templates/institutionAdmin.dust",
  "institutionProfileFieldRow": "lib/dust/compiled_templates/institutionAdmin.dust",
  "institutionTemplateRow": "lib/dust/compiled_templates/institutionAdmin.dust",
  "institutionUsersTable": "lib/dust/compiled_templates/institutionAdmin.dust",
  "userTableRow": "lib/dust/compiled_templates/institutionAdmin.dust",
  "userField": "lib/dust/compiled_templates/institutionUserGroups.dust",
  "userGroup": "lib/dust/compiled_templates/institutionUserGroups.dust",
  "badgesList": "lib/dust/compiled_templates/misc.dust",
  "competitionEntry": "lib/dust/compiled_templates/misc.dust",
  "competitionEntryPreview": "lib/dust/compiled_templates/misc.dust",
  "courseBox": "lib/dust/compiled_templates/misc.dust",
  "pagesList": "lib/dust/compiled_templates/misc.dust",
  "profileCourseList": "lib/dust/compiled_templates/misc.dust",
  "profileUserList": "lib/dust/compiled_templates/misc.dust",
  "userList": "lib/dust/compiled_templates/misc.dust",
  "moduleEdit": "lib/dust/compiled_templates/moduleEditor.dust",
  "modulePageBox": "lib/dust/compiled_templates/moduleEditor.dust",
  "moduleSearchBox": "lib/dust/compiled_templates/moduleEditor.dust",
  "courseDropdownMenu": "lib/dust/compiled_templates/page.dust",
  "relatedContent": "lib/dust/compiled_templates/page.dust",
  "payments": "lib/dust/compiled_templates/payments.dust",
  "missingProfileInfo": "lib/dust/compiled_templates/profile.dust",
  "updateProfileInfo": "lib/dust/compiled_templates/profile.dust",
  "historicComment": "lib/dust/compiled_templates/progress.dust",
  "historicComments": "lib/dust/compiled_templates/progress.dust",
  "activity": "lib/dust/compiled_templates/scheduleEditor.dust",
  "activityCategory": "lib/dust/compiled_templates/scheduleEditor.dust",
  "mediaUpload": "lib/dust/compiled_templates/scheduleEditor.dust",
  "scheduleEditorContentItem": "lib/dust/compiled_templates/scheduleEditor.dust",
  "scheduleEditorModule": "lib/dust/compiled_templates/scheduleEditor.dust",
  "trashItem": "lib/dust/compiled_templates/scheduleEditor.dust",
  "searchResult": "lib/dust/compiled_templates/search.dust",
  "sharedItem": "lib/dust/compiled_templates/sharing.dust",
  "sharingBar": "lib/dust/compiled_templates/sharing.dust",
  "sharingPost": "lib/dust/compiled_templates/sharing.dust",
  "sharingPostsContainer": "lib/dust/compiled_templates/sharing.dust",
  "student-stats": "lib/dust/compiled_templates/stats.dust",
  "groupRow": "lib/dust/compiled_templates/teacherAccess.dust",
  "teacherAccessUserTableRow": "lib/dust/compiled_templates/teacherAccess.dust",
  "teacherRow": "lib/dust/compiled_templates/teacherAccess.dust",
  "testTemplate": "lib/dust/compiled_templates/test.dust"
};
